var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"box-shadow-none",class:{
    'left--70': _vm.condition.showActions && _vm.conditionIndex !== 0,
  }},[_c('div',{staticClass:"bg-color--deepblue"},[_c('div',[_c('div',[_c('div',{ref:"conditionForm"},[(_vm.showFirstSelection)?_c('div',{staticClass:"rounded border border-gray001 p-4 mb-4"},_vm._l((_vm.selects),function(select){return _c('t-select',{key:select.id,staticClass:"mb-3",attrs:{"options":_vm.getValueOptions(select.key),"label":select.key,"placeholder":select.key},on:{"change":function($event){return _vm.selectChanged($event, select)}}})}),1):_vm._e(),(_vm.condition.secondSectionEditable)?_c('div',{staticClass:"rounded border border-gray001 p-4 mb-4",class:{
              'relative left--70': _vm.condition.showActions
            }},[(!_vm.wellStateSelected)?_c('div',{staticClass:"grid-min-max"},[_c('span',{staticClass:"w-4"},[_vm._v("If")]),_c('t-select',{attrs:{"options":_vm.operationalModifiers,"label":"Value","return-object":""},model:{value:(_vm.workflow.conditions[_vm.conditionIndex].operationalModifier),callback:function ($$v) {_vm.$set(_vm.workflow.conditions[_vm.conditionIndex], "operationalModifier", $$v)},expression:"workflow.conditions[conditionIndex].operationalModifier"}})],1):_vm._e(),(!_vm.wellStateSelected)?_c('div',{staticClass:"grid-min-max"},[_c('span',{staticClass:"w-4"},[_vm._v("is")]),_c('t-select',{attrs:{"options":_vm.modifierValues,"label":"Modifier","return-object":""},model:{value:(_vm.workflow.conditions[_vm.conditionIndex].valueModifier),callback:function ($$v) {_vm.$set(_vm.workflow.conditions[_vm.conditionIndex], "valueModifier", $$v)},expression:"workflow.conditions[conditionIndex].valueModifier"}})],1):_vm._e(),(_vm.wellStateSelected)?_c('div',{staticClass:"grid items-center grid-flow-col justify-start gap-x-2"},[_c('t-toggle',{attrs:{"label":"Recurring"},model:{value:(_vm.condition.recurring),callback:function ($$v) {_vm.$set(_vm.condition, "recurring", $$v)},expression:"condition.recurring"}}),_c('span',[_vm._v(" Recurring ")])],1):_vm._e(),(_vm.wellStateSelected ? _vm.condition.recurring : true)?_c('div',{staticClass:"mb-3",class:{
                'grid-min-max grid-min-max-min': _vm.wellStateSelected
              }},[(_vm.wellStateSelected)?_c('span',{staticClass:"w-20"},[_vm._v(" "+_vm._s(`Has been ${(_vm.condition.conditionValue || {}).text || ''}`)+" ")]):_vm._e(),_c('t-input',{attrs:{"label":_vm.getLabelForValue(),"placeholder":_vm.getLabelForValue(),"size":"1","min":"0","max":"9","pattern":"[0-9.]*","lang":"en-US","inputmode":"decimal"},model:{value:(_vm.workflow.conditions[_vm.conditionIndex].value),callback:function ($$v) {_vm.$set(_vm.workflow.conditions[_vm.conditionIndex], "value", $$v)},expression:"workflow.conditions[conditionIndex].value"}}),(_vm.wellStateSelected)?_c('span',{staticClass:"white-space-nowrap font--16"},[_vm._v(" times ")]):_vm._e()],1):_vm._e(),(_vm.wellStateSelected ? _vm.condition.recurring : true)?_c('div',{class:{
                'grid-min-max grid-min-max-min': _vm.wellStateSelected
              }},[(!_vm.wellStateSelected)?_c('p',{staticClass:"mb-1 color--lightbluegray font--16"},[_vm._v(" For how long: ")]):_vm._e(),(_vm.wellStateSelected)?_c('span',{staticClass:"white-space-nowrap w-12"},[_vm._v(" out of ")]):_vm._e(),_c('t-input',{attrs:{"label":_vm.wellStateSelected ? 'days' : 'hours',"placeholder":_vm.wellStateSelected ? 'days' : 'hours',"size":"1","min":"0","max":"9","pattern":"[0-9]*"},model:{value:(_vm.workflow.conditions[_vm.conditionIndex].howLong),callback:function ($$v) {_vm.$set(_vm.workflow.conditions[_vm.conditionIndex], "howLong", $$v)},expression:"workflow.conditions[conditionIndex].howLong"}}),(_vm.wellStateSelected)?_c('span',{staticClass:"w-28"},[_vm._v(" previous days ")]):_vm._e()],1):_vm._e(),_c('button',{staticClass:"h-10 w-full my-3 rounded-lg text-sm text-gray001 border mt-4 border-gray001 capitalize",attrs:{"disabled":_vm.saveButtonDisabled},on:{"click":_vm.saveCustom}},[_vm._v(" Save ")])]):_vm._e()])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }